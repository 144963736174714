<template lang="pug">
v-main
  router-view.default-layout.bg-gray-200
</template>

<script>
export default {
  name: 'DefaultLayout'
}
</script>
